import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Token from "../components/Token";
import { styles } from "../components/CsStyles";
import { VendorTable } from "../components/VendorTable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CountryCodes } from "../Constants.js";

/*
 ** Vendors - a list of vendors based on search criteria passed-in
 */
class Vendors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor_name: "",
      vendor_id: "",
      chain_id: "",
      house_id: "",
      country: [],
      search_vendor_name: "",
      search_vendor_id: "",
      search_chain_id: "",
      search_house_id: "",
      search_country: "",
      open: false,
    };

    // Title
    if (this.props.setTitle != null) {
      this.props.setTitle("Vendors");
    }

    this.fetchData = this.fetchData.bind(this);
    this.clearData = this.clearData.bind(this);
  }

  handleChange = (name) => (event, data) => {
    if (name === "country") {
      this.setState({
        [name]: data,
      });
      return;
    }
    this.setState({
      [name]: event.target.value,
    });
  };

  // Button click
  handleClickOpen(vendorId) {
    if (vendorId !== this.state.selectedVendor) {
      console.log("Vendor Table: " + vendorId);
      this.setState({ selectedVendor: vendorId });
    }
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      this.fetchData();
    }
  }

  /*
   **
   ** fetchData
   **
   */
  fetchData() {
    this.setState({
      search_vendor_name: this.state.vendor_name,
      search_vendor_id: this.state.vendor_id,
      search_chain_id: this.state.chain_id,
      search_house_name: this.state.house_name,
      search_country: this.state.country.join(","),
    });
  }

  /*
   **
   ** clearData
   **
   */
  clearData() {
    this.setState({
      search_vendor_name: "",
      search_vendor_id: "",
      search_chain_id: "",
      search_house_id: "",
      search_country: "",
      vendor_name: "",
      vendor_id: "",
      chain_id: "",
      house_id: "",
      country: [],
    });
  }

  /*
   ** render
   **
   */
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Token />
        <CssBaseline />
        <div className={classes.root}>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />

            {/* Form begins here */}
            <form className={classes.csMenu} noValidate autoComplete="off">
              <Grid container>
                <TextField
                  id="vendor_name"
                  label="Vendor Name"
                  autoFocus
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.name}
                  onChange={this.handleChange("vendor_name")}
                  margin="normal"
                />
                <TextField
                  id="vendor_id"
                  label="Vendor ID"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.vendor_id}
                  onChange={this.handleChange("vendor_id")}
                  margin="normal"
                />
                <TextField
                  id="chain_id"
                  label="Chain ID"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.chain_id}
                  onChange={this.handleChange("chain_id")}
                  margin="normal"
                />
                <TextField
                  id="house_id"
                  label="House ID"
                  onKeyPress={this.enterPressed.bind(this)}
                  className={classes.textField}
                  value={this.state.house_id}
                  onChange={this.handleChange("house_id")}
                  margin="normal"
                />
                <Autocomplete
                  multiple
                  id="countries"
                  options={Object.keys(CountryCodes)}
                  filterSelectedOptions
                  onChange={this.handleChange("country")}
                  onKeyPress={this.enterPressed.bind(this)}
                  value={this.state.country}
                  className={classes.searchSelectField}
                  renderInput={(params) => (
                    <TextField
                      className={classes.searchTextField}
                      {...params}
                      variant="outlined"
                      margin="normal"
                      label="Country"
                      placeholder="Pick Country Name"
                    />
                  )}
                />

                <Button
                  ref="autocomplete"
                  onClick={this.fetchData}
                  className={classes.button}
                >
                  Search
                </Button>
                <Button onClick={this.clearData} className={classes.button}>
                  Clear
                </Button>
              </Grid>
            </form>

            <div className={classes.tableContainer}>
              <VendorTable
                vendor_name={this.state.search_vendor_name}
                vendor_id={this.state.search_vendor_id}
                chain_id={this.state.search_chain_id}
                house_id={this.state.search_house_id}
                country={this.state.search_country}
              />
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Vendors.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vendors);
