import React, { useEffect, useState } from "react";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { fetchData } from "../utils/fetchData";

const getStatusFromData = (data) =>
  !!data
    ? !data.updated_at
      ? "Queued for sending"
      : `Manually sent at ${new Date(data.updated_at).toLocaleString()}`
    : "Send to web";

const SendToWeb = ({ houseId, chainId, color = "primary" }) => {
  const [status, setStatus] = useState(getStatusFromData());
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (hovered && houseId) {
      fetchData({
        endpoint: `sendtoweb/getLatest`,
        body: {
          house_id: houseId,
        },
        handleComplete: (response) => {
          setStatus(getStatusFromData(response.data));
        },
      });
    }
  }, [hovered, houseId]);

  const handleSend = () => {
    fetchData({
      endpoint: `sendtoweb/create`,
      body: {
        house_id: houseId,
        chain_id: chainId,
      },
      onComplete: (response) => {
        if (houseId) {
          setStatus(getStatusFromData(response.data));
        }
      },
    });
  };

  return (
    <Tooltip placement="top" title={status}>
      <IconButton
        onClick={handleSend}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{ color }}
      >
        <UploadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SendToWeb;
