import React, { useEffect, useReducer, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TimerIcon from "@material-ui/icons/Timer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import EnableIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";
import orderBy from "lodash/orderBy";
import DownIcon from "@material-ui/icons/ArrowDownward";
import UpIcon from "@material-ui/icons/ArrowUpward";
import InputLabel from "@material-ui/core/InputLabel";

import { fetchData } from "../../utils/fetchData";
import Form from "./Form";
import { STORAGE_AUTOMATER_KEY, STORAGE_SEARCH_KEY } from "./constants";
import Token from "../Token";

const Imports = ({ setTitle }) => {
  const [loading, setLoading] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [search, setSearch] = useState("");
  const [automater, setAutomater] = useState("");
  const [editId, setEditId] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortAsc, setSortAsc] = useState(true);
  const [imports, dispatch] = useReducer((values, action) => {
    switch (action.type) {
      case "SET_ALL":
        return action.imports;
      case "ADD_NEW":
        return [action.newImport, ...values];
      case "UPDATE":
        return values.map((importRow) =>
          importRow.id === action.updateImport.id
            ? action.updateImport
            : importRow,
        );
      case "REMOVE":
        return values.filter((importRow) => importRow.id !== action.id);
      default:
        throw Error("Unknown action.");
    }
  }, []);

  useEffect(() => {
    setTitle("Imports");
    setLoading(true);
    fetchData({
      endpoint: "imports/list",
      handleComplete: (response) => {
        dispatch({ type: "SET_ALL", imports: response.data });
      },
      handleFinally: () => setLoading(false),
    });

    const storedAutomater = sessionStorage.getItem(STORAGE_AUTOMATER_KEY);
    if (storedAutomater) {
      setAutomater(storedAutomater);
    }

    const storedSearch = sessionStorage.getItem(STORAGE_SEARCH_KEY);
    if (storedSearch) {
      setSearch(storedSearch);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(STORAGE_AUTOMATER_KEY, automater);
  }, [automater]);

  useEffect(() => {
    sessionStorage.setItem(STORAGE_SEARCH_KEY, search);
  }, [search]);

  const filteredImports = imports.filter((row) => {
    if (!!automater && row.automater.trim() !== automater) {
      return false;
    }
    if (!showDisabled && !row.enabled) {
      return false;
    }
    if (search) {
      let found = false;
      for (const key in row) {
        if (typeof row[key] === "string") {
          if (row[key].toLowerCase().includes(search)) {
            found = true;
          }
        }
      }
      return found;
    }
    return true;
  });

  const handleSetSorting = (nextSortField) => {
    if (nextSortField !== sortField) {
      setSortField(nextSortField);
      setSortAsc(true);
    } else {
      setSortAsc(!sortAsc);
    }
  };

  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "60rem",
        padding: "0 1rem",
        width: "100%",
      }}
    >
      <Token />
      <div style={{ display: "flex", gap: "1rem", padding: "1rem 0" }}>
        <a
          href="https://metabase.allocine.net/dashboard/199-importstracking#refresh=60"
          target="_blank"
        >
          Imports Tracking
        </a>
        <a
          href="https://metabase.allocine.net/question/928-getshowtimestheaters"
          target="_blank"
        >
          Get Athena URLs
        </a>
      </div>
      <div
        style={{
          backgroundColor: "#fafafa",
          position: "sticky",
          top: "0",
          zIndex: 1,
        }}
      >
        <div
          style={{
            alignItems: "baseline",
            display: "flex",
            gap: "1rem",
            justifyContent: "start",
            padding: "1rem 0",
            width: "100%",
          }}
        >
          <FormControl>
            <InputLabel id="automater-filter">Automater</InputLabel>
            <Select
              value={automater}
              name="automater"
              labelId="automater-filter"
              onChange={(e) => {
                setAutomater(e.target.value);
              }}
              style={{
                width: "6.25em",
              }}
              disabled={loading}
            >
              <MenuItem value="">All automaters</MenuItem>
              {imports
                .reduce(
                  (automaters, importRow) =>
                    !automaters.includes(importRow.automater.trim())
                      ? [...automaters, importRow.automater.trim()]
                      : automaters,
                  [],
                )
                .sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0))
                .map((auto) => (
                  <MenuItem key={`automater-option-${auto}`} value={auto}>
                    {auto}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <TextField
              label={"Search"}
              name="search"
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              margin="normal"
            />
          </FormControl>

          <FormLabel>
            Show disabled
            <Checkbox
              checked={showDisabled}
              onChange={() => setShowDisabled(!showDisabled)}
              title="Show disabled"
            ></Checkbox>
          </FormLabel>
          <FormControl>
            <Button
              color="primary"
              onClick={() => {
                setEditId(0);
                setIsNew(true);
              }}
              variant="contained"
            >
              Add new import
            </Button>
          </FormControl>
        </div>
        <div
          style={{
            alignItems: "center",
            borderBottom: "1px solid #666",
            display: "grid",
            gridTemplateColumns: "6fr 3fr 1fr 1fr 1fr 1fr 1fr",
            gridTemplateRows: "3.125rem",
            gap: "1rem",
            padding: "0.5rem 0",
            position: "sticky",
            top: 0,
          }}
        >
          <div
            onClick={() => handleSetSorting("name")}
            style={{ alignItems: "center", cursor: "pointer", display: "flex" }}
            role="button"
          >
            Name{" "}
            {sortField === "name" ? sortAsc ? <DownIcon /> : <UpIcon /> : null}
          </div>
          <div
            onClick={() => handleSetSorting("lastrun")}
            style={{ alignItems: "center", cursor: "pointer", display: "flex" }}
            role="button"
          >
            Last run{" "}
            {sortField === "lastrun" ? (
              sortAsc ? (
                <DownIcon />
              ) : (
                <UpIcon />
              )
            ) : null}
          </div>
        </div>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        orderBy(
          filteredImports,
          [
            (o) =>
              sortField === "name"
                ? o.name.toLowerCase()
                : o.lastrun || "1900-01-01",
            (o) =>
              sortField === "name"
                ? o.lastrun || "1900-01-01"
                : o.name.toLowerCase(),
          ],
          [sortAsc ? "asc" : "desc", "asc"],
        ).map((importRow) => (
          <div
            key={importRow.id}
            style={{
              alignItems: "center",
              borderBottom: "1px solid #666",
              display: "grid",
              gridTemplateColumns: "6fr 3fr 1fr 1fr 1fr 1fr 1fr",
              gridTemplateRows: "3.125rem",
              gap: "1rem",
              padding: "0.5rem 0",
            }}
          >
            <strong style={{ fontSize: "1rem" }}>{importRow.name}</strong>

            <div>
              {importRow.lastrun
                ? new Date(importRow.lastrun).toLocaleString()
                : null}
            </div>
            <div>{importRow.status}</div>

            <Tooltip placement="top" title="Edit">
              <IconButton
                onClick={() => setEditId(importRow.id)}
                color="secondary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Reset timestamp">
              <IconButton
                onClick={() =>
                  fetchData({
                    endpoint: "imports/resetTime",
                    body: { id: importRow.id },
                    handleComplete: (response) => {
                      dispatch({ type: "UPDATE", updateImport: response.data });
                    },
                  })
                }
              >
                <TimerIcon />
              </IconButton>
            </Tooltip>

            <Tooltip placement="top" title="Duplicate import">
              <IconButton
                onClick={() => {
                  setIsNew(true);
                  setEditId(importRow.id);
                }}
                color="primary"
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top"
              title={importRow.enabled ? "Enabled" : "Disabled"}
            >
              <IconButton
                onClick={() =>
                  fetchData({
                    endpoint: "imports/toggle",
                    body: { id: importRow.id },
                    handleComplete: (response) => {
                      dispatch({ type: "UPDATE", updateImport: response.data });
                    },
                  })
                }
                color="primary"
              >
                {importRow.enabled ? (
                  <EnableIcon htmlColor="green" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </IconButton>
            </Tooltip>
          </div>
        ))
      )}

      {editId !== null && (
        <Form
          onClose={() => setEditId(null)}
          data={imports.find(({ id }) => id === editId)}
          onComplete={(data) => {
            if (!editId || isNew) {
              dispatch({ type: "ADD_NEW", newImport: data });
              if (isNew) {
                setIsNew(false);
                setEditId(data.id);
              }
            } else {
              dispatch({ type: "UPDATE", updateImport: data });
            }
          }}
          isNew={isNew}
        />
      )}
    </div>
  );
};

export default Imports;
