import React from "react";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { configState } from "../Config";
import { createSortHandler } from "../components/TableSortHelperMethods";
import VendorDetail from "./VendorDetail";

var component = this;

/*
 **
 ** VendorTable
 **
 */
export class VendorTable extends React.Component {
  /*
   ** constructor
   **/
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search, // search parameter, text
      vendor_name: this.props.vendor_name,
      vendor_id: this.props.vendor_id, // vendor_id
      chain_id: this.props.chain_id,
      house_id: this.props.house_id,
      country: this.props.country,
      selectedVendor: 0, // selected vendor for detail section
      onSelect: this.props.onSelect, // callback for selection, return code or vendor_id
      isLoading: true,
      dataSource: [],
    };

    this.redoQuery = this.redoQuery.bind(this);
    this.redoQuery(
      this.props.vendor_name,
      this.props.vendor_id,
      this.props.chain_id,
      this.props.house_id,
      this.props.country
    );
    component = this;
  }

  /*
   ** componentDidMount - when first mounting, do query, which is probably empty
   */
  componentDidMount() {
    this.redoQuery("", "", "", "", "");
  }

  /*
   ** Button click
   */
  handleClickOpen(vendorId) {
    if (vendorId !== this.state.selectedVendor)
      this.setState({ selectedVendor: vendorId });
  }

  // Return from modal
  handleCloseModal = () => {
    this.setState({ selectedVendor: 0 });
  };

  /*
   ** redoQuery - query from AJAX
   */
  async redoQuery(vendor_name, vendor_id, chain_id, house_id, country) {
    var url = "";

    //url = configState.apiBase + 'vendors?function=search'
    url = configState.apiBase + "vendors.php?function=search";

    if (vendor_id !== "") url += "&vendor_id=" + vendor_id;
    if (vendor_name !== "") url += "&vendor_name=" + vendor_name;
    if (chain_id !== "") url += "&chain_id=" + chain_id;
    if (house_id !== "") url += "&house_id=" + house_id;
    if (country !== "") url += "&country=" + country;

    console.log("Query URL: " + url);
    return fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState(
          {
            isLoading: false,
            dataSource: responseJson.rows == null ? [] : responseJson.rows,
          },
          function () {
            // do something with new state
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps) {
    // if nothing important changed, we're done.
    if (
      prevProps.search === this.props.search &&
      prevProps.vendor_name === this.props.vendor_name &&
      prevProps.vendor_id === this.props.vendor_id &&
      prevProps.chain_id === this.props.chain_id &&
      prevProps.house_id === this.props.house_id &&
      prevProps.country === this.props.country
    )
      return;

    this.setState({
      search: this.props.search,
      vendor_name: this.props.vendor_name,
      vendor_id: this.props.vendor_id,
      chain_id: this.props.chain_id,
      house_id: this.props.house_id,
      country: this.props.country,
      onSelect: this.props.onSelect,
      isLoading: true,
      dataSource: [],
    });

    // New props, have to rerun query
    this.redoQuery(
      this.props.vendor_name,
      this.props.vendor_id,
      this.props.chain_id,
      this.props.house_id,
      this.props.country,
      this.props.search
    );
  }

  /*
   ** render
   */
  render() {
    console.log("re-rendering vendor table");
    // Still loading, show animation
    if (this.state.isLoading) {
      console.log("Is LOADING");
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <span className="glyphicon-left glyphicon glyphicon-refresh spinning"></span>
        </div>
      );
    } else if (
      this.state.dataSource.length === 0 &&
      (this.state.search !== "" || this.state.movie_id !== "")
    ) {
      return (
        <div style={{ flex: 1, paddingTop: 20 }}>
          <h4>No Results</h4>
        </div>
      );
    } else {
      /* Vendor table */
      return (
        <div>
          <VendorDetail
            selectedVendor={this.state.selectedVendor}
            onClose={this.handleCloseModal}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "vendor_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("vendor_id", this)}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "vendorname"}
                    direction={this.state.order}
                    onClick={createSortHandler("vendorname", this)}
                  >
                    Vendor Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "vendorid"}
                    direction={this.state.order}
                    onClick={createSortHandler("vendorid", this)}
                  >
                    Vendor ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "chain_id"}
                    direction={this.state.order}
                    onClick={createSortHandler("chain_id", this)}
                  >
                    Chain ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "houseids"}
                    direction={this.state.order}
                    onClick={createSortHandler("houseids", this)}
                  >
                    House IDs
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "vendorlogo"}
                    direction={this.state.order}
                    onClick={createSortHandler("vendorlogo", this)}
                  >
                    Vendor Logo
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={this.state.orderBy === "vendorcountries"}
                    direction={this.state.order}
                    onClick={createSortHandler("vendorcountries", this)}
                  >
                    Vendor Countries
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataSource.map((row) => {
                var vendor_logo = "";
                if (row.vendorlogo === "") {
                  var logo_url =
                    "http://www.movienewsletters.net/vendorlogos/" +
                    row.vendorid +
                    ".png";
                  vendor_logo = (
                    <img
                      src={logo_url}
                      style={{ width: 25, textAlign: "middle" }}
                      alt=""
                    />
                  );
                } else {
                  vendor_logo = (
                    <img
                      src={row.vendorlogo}
                      style={{ width: 25, textAlign: "middle" }}
                      alt=""
                    />
                  );
                }
                return (
                  <TableRow key={row.vendor_id} style={{ cursor: "pointer" }}>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          component.handleClickOpen(row.vendor_id);
                        }}
                        align="right"
                      >
                        <InfoIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.vendor_id}
                    </TableCell>
                    <TableCell>{row.vendorname}</TableCell>
                    <TableCell>{row.vendorid}</TableCell>
                    <TableCell>
                      <Link
                        underline="hover"
                        style={{ color: "black" }}
                        href={"/schedule/" + row.chain_id}
                      >
                        {row.chain_id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {row.houseids.split(",").map((id, index) => (
                        <React.Fragment key={index}>
                          <Link
                            underline="hover"
                            href={"/schedule/" + row.chain_id + "/" + id}
                            style={{ color: "black" }}
                          >
                            {id}
                          </Link>
                          {index !== row.houseids.split(",").length - 1
                            ? ", "
                            : null}
                        </React.Fragment>
                      ))}
                    </TableCell>
                    <TableCell>{vendor_logo}</TableCell>
                    <TableCell>{row.vendorcountries}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    } // not isLoading
  } // render
} // VendorTable.js
