import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import { configState } from "../Config";
import SearchDetailSection from "./SearchDetailSection";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import { BoxofficeRed } from "./CsStyles";

/* Style */
const styles = (theme) => ({
  searchResultsClass: {
    margin: "auto",
  },
});

class VendorDetail extends React.Component {
  state = {
    open: false,
    selectedVendor: 0,
    edit: true,
    record: null,
    ChainStats: {},
    hasPerf: false,
  };

  // handleClose
  handleClose = () => {
    this.setState({
      open: false,
    });
    this.props.onClose();
  };

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }
  /*
   ** executeQuery - get data from API
   */
  async executeQuery(selectedVendor) {
    var url = configState.apiBase + "vendors.php?function=search";
    if (selectedVendor !== "") url += "&vendor_id=" + selectedVendor;
    console.log("Loading: " + url);

    try {
      const vendorResponse = await fetch(url);
      const vendorJSON = await vendorResponse.json();

      url = configState.apiBase + "chains?function=stats";
      let statsJSON = {};

      if (vendorJSON.rows[0]["vendorsite"] !== null) {
        vendorJSON.rows[0]["vendorsite"] = (
          <Link
            underline="hover"
            style={{ color: "black" }}
            href={vendorJSON.rows[0]["vendorsite"]}
          >
            {vendorJSON.rows[0]["vendorsite"]}
          </Link>
        );
      }

      if (vendorJSON.rows[0].vendorlogo === "") {
        vendorJSON.rows[0].vendorlogo =
          "http://www.movienewsletters.net/vendorlogos/" +
          vendorJSON.rows[0].vendorid +
          ".png";
      }

      //get the chain stats
      if (
        vendorJSON.rows[0]["chain_id"] !== "" &&
        vendorJSON.rows[0]["chain_id"] !== 0
      ) {
        url += "&chain_id=" + vendorJSON.rows[0]["chain_id"];
        if (vendorJSON.rows[0]["vendorcountries"] !== "")
          url += "&country=" + vendorJSON.rows[0]["vendorcountries"];
        console.log("Loading: " + url);
        const statsResponse = await fetch(url);
        statsJSON = await statsResponse.json();
        statsJSON = statsJSON.rows[0];

        statsJSON["percenthouses"] = (
          parseFloat(statsJSON["percenthouses"]) * 100
        ).toFixed(2);
        statsJSON["percentscreens"] = (
          parseFloat(statsJSON["percentscreens"]) * 100
        ).toFixed(2);
      }

      /* Now search for perfs */
      let foundPerfs = false;

      /* First search by chain_id (and optionally linkpattern) */
      if (vendorJSON.rows[0]["chain_id"] !== "") {
        var perfsUrl =
          configState.apiBase +
          "perfs?function=search&chain_id=" +
          vendorJSON.rows[0]["chain_id"];
        if (vendorJSON.rows[0]["linkpattern"] !== "") {
          perfsUrl += "&wildcard=" + vendorJSON.rows[0]["linkpattern"];
        }
        const perfsResponse = await fetch(perfsUrl);
        const perfsJSON = await perfsResponse.json();

        foundPerfs = perfsJSON.rows != null && perfsJSON.rows.length > 0;
      }

      /* If there were none found, and there are house_ids in the config, search by them. */
      if (!foundPerfs && vendorJSON.rows[0]["houseids"] !== "") {
        const perfsResponse = await fetch(
          configState.apiBase +
          "perfs?function=search&house_id=" +
          vendorJSON.rows[0]["houseids"]
        );
        const perfsJSON = await perfsResponse.json();

        foundPerfs = perfsJSON.rows != null && perfsJSON.rows.length > 0;
      }

      this.setState(
        {
          isLoading: false,
          record: vendorJSON.rows == null ? [] : vendorJSON.rows[0],
          ChainData: statsJSON == null ? {} : statsJSON,
          hasPerf: foundPerfs,
        },
        function () {
          // do something with new state
          console.log("VendorDetail: got new data state.");
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  /*
   ** handleChange - handle the change in a text field, to make it permanent
   */
  handleChange = (row, name) => (event) => {
    let newRec = this.state.record;
    newRec[name] = event.target.value;
    this.setState({ record: newRec });
  };

  /*
   ** Got new props. So change state if they really changed.
   */
  componentDidUpdate(prevProps, prevState) {

    // If nothing important changed, we're done.
    if (
      prevProps.selectedVendor === this.props.selectedVendor ||
      prevProps.open === true
    )
      return;

    console.log(
      "VendorDetail: got new props with vendor id " + this.props.selectedVendor
    );

    if (this.props.selectedVendor > 0) {
      this.setState({ open: true });
      this.executeQuery(this.props.selectedVendor);
    } else {
      this.setState({ open: false });
    }

    this.setState({
      selectedVendor: this.props.selectedVendor, // selectedVendor parameter
    });
  }

  /*
   ** render
   */
  render() {
    const { classes } = this.props;

    var VendorName = "";
    if (this.state.record !== null) {
      var row = this.state.record;
      VendorName = row.name;
    } else return null;

    return (
      <Dialog
        className={classes.searchResultsClass}
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <div
          style={{
            backgroundColor: BoxofficeRed,
          }}
        >
          <img src={row.vendorlogo} alt={""} style={{ width: "100%" }} />

          <div
            style={{
              padding: "1em",
              margin: "0",
              color: "white",
            }}
          >
            <h1>{VendorName}</h1>
            <h2>
              Vendor ID: {row ? row.vendor_id : null}
              <br />
              {row.chain_id ? (
                <React.Fragment>
                  Chain ID:{" "}
                  <Link
                    underline="hover"
                    style={{ color: "white" }}
                    href={"/schedule/" + row.chain_id}
                  >
                    {row.chain_id}
                  </Link>
                  <br />
                </React.Fragment>
              ) : null}
              {row.houseids ? (
                <React.Fragment>
                  House IDs :{" "}
                  {row.houseids.split(",").map((id, index) => (
                    <React.Fragment key={index}>
                      <Link
                        underline="hover"
                        href={"/schedule/" + row.chain_id + "/" + id}
                        style={{ color: "white" }}
                      >
                        <span>{id}</span>
                      </Link>
                      {index !== row.houseids.split(",").length - 1 ? ", " : ""}
                    </React.Fragment>
                  ))}
                  <br />
                </React.Fragment>
              ) : null}
              Found Perf:{" "}
              {this.state.hasPerf ? (
                <CheckIcon style={{ verticalAlign: "middle" }} />
              ) : (
                <ClearIcon style={{ verticalAlign: "middle" }} />
              )}
            </h2>
          </div>
        </div>
        <DialogContent>
          {this.state.record ? (
            <div className={classes.advancedSearch}>
              {SearchDetailSection(
                row,
                {
                  vendorcountries: "Country ",
                  housepattern: "House Pattern ",
                  linkpattern: "Link Pattern ",
                  vendorsite: "Site ",
                },
                ""
              )}
              {SearchDetailSection(
                this.state.ChainData,
                {
                  totalhouses: "House Count ",
                  totalscreens: "Screen Count ",
                  percenthouses: "House Ratio in Country ",
                  percentscreens: "Screen Ratio in Country ",
                },
                "Stats"
              )}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(VendorDetail);
