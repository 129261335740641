import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { BoxofficeRed } from "./CsStyles";

import ExpandLess from "@material-ui/icons/ExpandLess";

/*
 ** formatHouse - show theater if this is a new house
 */
export default function ShowtimesHouseCard(row, data, parent) {
  if (row.house_id === data.oldHouse) return;
  else {
    data.movieCounter = 0;
    data.houseCounter++;
    data.oldHouse = row.house_id;
    data.oldMovie = 0;

    var buttonText;
    var icon;
    if (data.showExtra === "none") {
      buttonText = "SHOW ME ALL MOVIES AVAILABLE AT THIS THEATRE";
      icon = <ExpandMore />;
    } else {
      buttonText = "HIDE MOVIES FROM THIS THEATRE";
      icon = <ExpandLess />;
    }
    return (
      <Container className="main-container" disableGutters maxWidth={false}>
        <Box bgcolor={BoxofficeRed} color="white" p={1} cursor="pointer">
          <div
            className={row.house_id}
            id={data.houseCounter}
            style={{ fontSize: 15 }}
          >
            <b style={{ fontSize: 25, padding: 5 }}>
              {row.name}
              <IconButton
                onClick={() => {
                  parent.setState({
                    selectedHouse: parseInt(row.house_id, 10),
                    houseOpen: true,
                  });
                  console.log("Selected House: " + parent.state.selectedHouse);
                }}
                align="right"
                style={{ color: "white" }}
              >
                <InfoIcon />
              </IconButton>
            </b>
            <br></br>
            <small style={{ padding: 5 }}>
              {row.city}, {row.state}, {row.country} {row.zip}
            </small>
          </div>
        </Box>
        <div className="show-all-button" style={{ marginTop: 5 }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "white", shadowRadius: 10, fontSize: 12 }}
            startIcon={icon}
            onClick={() => {
              parent.setState({});
              if (data.showExtra === "none") {
                data.showExtra = "";
              } else {
                data.showExtra = "none";
              }
            }}
          >
            {buttonText}
          </Button>
        </div>
      </Container>
    );
  }
}
